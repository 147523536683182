import { useState } from 'react';

export interface UseModalValue {
  isShowing: boolean;
  isClosing: boolean;
  hide: () => void;
  open: () => void;
  close: () => void;
}

interface UseModalProps {
  animateClose?: boolean;
  animationTimeout?: number;
  startOpen?: boolean;
}

export const useModal = ({
  animateClose,
  animationTimeout = 500,
  startOpen = false,
}: UseModalProps = {}): [UseModalValue, () => void] => {
  const [isShowing, setIsShowing] = useState(startOpen);
  const [isClosing, setIsClosing] = useState(false);

  function toggle() {
    if (animateClose && isShowing) {
      setIsClosing(true);
      setTimeout(() => {
        setIsShowing(false);
        setIsClosing(false);
      }, animationTimeout);
    } else {
      setIsShowing(!isShowing);
    }
  }

  function close() {
    if (animateClose && isShowing) {
      setIsClosing(true);
      setTimeout(() => {
        setIsShowing(false);
        setIsClosing(false);
      }, animationTimeout);
    } else {
      setIsShowing(false);
    }
  }

  function open() {
    setIsShowing(true);
  }

  const modalValue = {
    isShowing,
    isClosing,
    hide: toggle,
    close,
    open,
  };

  return [modalValue, toggle];
};

export default useModal;
